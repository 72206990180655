.br {
  border: 1px solid black;
  padding: 20px;
  margin: 60px 50px 60px 50px;
  border-color: #d9d9d9;
  border-radius: 40px;
}

.navb {
  color: black;
}

.addrBr {
  color: #686569;
  border-radius: 40px;
  border: 1px solid black;
  border-color: #d9d9d9;
  padding: 10px 10px 10px 10px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.card {
  margin: 1.2%;
  border-radius: 30px;
  padding: 20px;
  border: 1.16px solid #0000001a;
  background: linear-gradient(
    180deg,
    rgba(237, 237, 237, 0) 0%,
    rgba(169, 169, 169, 0.2) 100%
  );
}
.logo {
  position: absolute;
  left: 0;
  top: 0;
}

#man {
  width: 30.5%;
}

.container {
  margin: 0px 0px 0px 0px;
  width: 100%;
}

#manage {
  background: linear-gradient(
    180deg,
    rgba(23, 111, 255, 0) 0%,
    rgba(22, 110, 255, 0.2) 100%
  );
}

.stakedTokens {
  font-size: 62.58px;
  line-height: 75.74px;
}
.bcx {
  font-size: 27.44px;
  display: inline;
}

.button {
  border-radius: 46.5px;
  border: 0px;
  background: #2275ff;
  color: #ffffff;
}
.connectbtn{
  border-radius: 44px;
  border: 0px;
  background: #2275ff;
  color: #ffffff;
  position: absolute;
  right: 0%;
  top: 10%;
}

.circle{
  width: 20px;
    height: 20px;
    background-color:rgb(87, 189, 87); 
    border-radius: 50%;
    margin-right: 10px;
}
.button2 {
  border-radius: 46.5px;
  border: 1px solid black;

  color: black;
}
.txt {
  color: #05a200;

  font-weight: 700;
  line-height: 40px;
}

.info {
  bottom: 20px;
  right: 45px;

  position: absolute;
  height: 15px;
}
.inf {
  bottom: 0px;
  right: 15px;
  position: absolute;
  font-size: 15px;
}

.contain {
  display: inline;
}

.title {
  font-size: 120%;
  font-weight: 500;
}

.labeltxt {
  font-size: 160%;
}

/* -----------------------------------------------------------------------------------------------------*/

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
.circle{
  width: 20px;
  height: 20px;
  background-color:rgb(87, 189, 87); 
  border-radius: 50%; 
  margin-right: 10px;
}
  .br {
    border: 1px solid black;
    padding: 20px;
    margin: 3% 6% 3% 6%;
    border-color: #d9d9d9;
    border-radius: 40px;
  }

  .navb {
    color: black;
  }

  .addrBr {
    color: #686569;
    border-radius: 40px;
    border: 1px solid black;
    border-color: #d9d9d9;
    position: relative;
    height: 100%;
    top: 100%;
    background-color: #d7d94e;
  }

  .card {
    margin: -2% 0% 8% 2%;
    width: 96%;
    border-radius: 30px;
    padding: 20px;
    border: 1.16px solid #0000001a;
    background-color: linear-gradient(
      180deg,
      rgba(237, 237, 237, 0) 0%,
      rgba(169, 169, 169, 0.2) 100%
    );
  }
  body {
    margin: 0%;
  }

  .logo {
    height: 100%;
    top: 0;
    left: 0;
  }
  #stInfo {
    padding: 0% 9% 0% 0%;
  }

  .container {
    margin: 0px 0px 0px 0px;
    width: 100%;
  }

  #manage {
    background: linear-gradient(
      180deg,
      rgba(23, 111, 255, 0) 0%,
      rgba(22, 110, 255, 0.2) 100%
    );
  }

  .stakedTokens {
    font-size: 300%;
    line-height: 100%;
  }
  .bcx {
    font-size: 27.44px;
    display: inline;
  }

  .button {
    border-radius: 46.5px;
    border: 4px solid #2275ff;
    background: #2275ff;
    color: #ffffff;
    margin: 0px 0px 9px 0px;
    
  }
  .connectbtn{
    border-radius: 46.5px;
    border: 4px solid #2275ff;
    background: #2275ff;
    color: #ffffff;
    margin: 0px 0px 9px 0px;
    position: absolute;
    right: 0%;
  }
  .button2 {
    border-radius: 46.5px;
    border: 1px solid black;
    color: black;
  }
  .txt {
    color: #05a200;

    font-weight: 700;
    line-height: 40px;
  }

  .info {
    bottom: 20px;
    right: 45px;

    position: absolute;
    height: 15px;
  }
  .inf {
    bottom: 0px;
    right: 15px;
    position: absolute;
    font-size: 15px;
  }

  #stakeBtn {
    border-radius: 46.5px;
    border: none;
    background: #2275ff;
    color: #ffffff;
    position: absolute;
    bottom: 0px;
    margin: 0%;
  }
  #man {
    width: 96%;
  }

  .labeltxt {
    font-size: 150%;
    padding: 0 !important;
  }
  .row{
    padding:15% 0% 0% 0%;
  }
}
