
.dropdown {
    position: absolute;
    display: inline-block;
    right: 0px;
    
  }
  
  .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 40px;
    cursor: pointer;
    background-color: white;
;
  }
  
  .dropdown-selected {
    display: flex;
    align-items: center;
  }
  
  .circle-icon {
    width: 20px;
    height: 20px;
    background-color: #ccc;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .dropdown-icon {
    margin-left: auto;
    color: #ccc;
  }
  
  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    z-index: 1;
  }
  
  .dropdown-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  