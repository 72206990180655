.custom-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 1000px; /* Increase width */

  max-height: 90vh;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto; /* Adds scrolling if content is too long */
}

.custom-modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

.video-container {
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.open-modal-button {
  padding: 6px 22px;
  background-color: #2275ff;
  color: #fff;
  border: 4px solid white;
  border-radius: 44px;
  position: absolute;
  right: 10%;
  top: 0%;
}

.open-modal-button:hover {
  background-color: #6c757d;
}

.close-modal-button {
  background-color: #d9534f;
    color: #fff;
    border: none;
    padding: 5px 20px;
    border-radius: 44px;
    cursor: pointer;
    /* margin-bottom: 7px; */
    position: absolute;
    top: 4%;
    right: 2%;
}

.close-modal-button:hover {
  background-color: #c9302c;
}
@media only screen and (max-width: 768px) {
  .open-modal-button {
    right: 0%;
    top: 112%;
    position: absolute;
    padding: 9px 22px;
  }
  .close-modal-button {
    background-color: #d9534f;
    color: #fff;
    border: none;
    padding: 5px 20px;
    border-radius: 44px;
    cursor: pointer;
    /* margin-bottom: 7px; */
    position: absolute;
    top: 7%;
    right: 6%;
  }
  .custom-modal-content {
    top:30%;
  }
}
@media (min-width: 1024px) and (max-width: 1366px) {
  
.open-modal-button {
  padding: 6px 22px;
  background-color: #2275ff;
  color: #fff;
  border: 4px solid white;
  border-radius: 44px;
  position: absolute;
  right: 16%;
  top: 0%;
}
  /* Styling for iPads 10.9" and larger in landscape */
}

/* Medium iPads in Portrait */
@media (min-width: 768px) and (max-width: 1023px) {
  /* Styling for iPads 8.3" and 10.2" in portrait */
  .open-modal-button {
    padding: 6px 22px;
    background-color: #2275ff;
    color: #fff;
    border: 4px solid white;
    border-radius: 44px;
    position: absolute;
    right: 18%;
    top: 0%;
  }
}
@media screen and (min-width: 820px) and (max-width: 1180px) and (orientation: portrait) {
  /* Styles for iPad Air in portrait orientation */
  .card {
    margin: -0.8% 3% 4% 3%;
    border-radius: 30px;
    padding: 20px;
    border: 1.16px solid #0000001a;
    background: linear-gradient(180deg, rgba(237, 237, 237, 0) 0%, rgba(169, 169, 169, 0.2) 100%);
}
}
@media screen and (min-width: 820px) and (max-width: 1180px) and (orientation: landscape) {
  /* Styles for iPad Air in landscape orientation */
  
  .card {
    margin: 1.2% 2% 2% 2%;
    border-radius: 30px;
    padding: 20px;
    border: 1.16px solid #0000001a;
    background: linear-gradient(180deg, rgba(237, 237, 237, 0) 0%, rgba(169, 169, 169, 0.2) 100%);
}
}