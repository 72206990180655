.toggle {
  width: 60%;
  height: 50%;
}

.radio-block {
  position: relative;
  height: 50px;
  border-radius: 40px;
  background-color: #1410171a;

  margin-top: 0px;
}

.selected {
  position: absolute;
  top: 5px;
  left: 10px;
  width: calc(50% - 10px);
  height: calc(100% - 10px);
  border-radius: 40px;
  transition: transform 0.2s;
  background-color: #1e1e1e;
}

label {
  position: relative;
  z-index: 100;
  width: 50%;
  padding: 12px !important;
  transition: color 0.2s;
  text-align: center;
  cursor: pointer;
}

input[type="radio"]:checked + label {
  color: #fff;
}

input[type="radio"]:checked + .off-label + .selected {
  transform: translateX(100%);
}

.sr-only,
input[type="radio"] {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
}

/* ------ Input field ------ */

form .form-row .input-data {
  height: 50px;
}

.input-data input,
.textarea textarea {
  height: 100%;
  justify-content: center;
  border: none;
  font-size: 17px;
  border-bottom: 5px solid #777778;
}
.inputField {
  background: linear-gradient(
    180deg,
    rgba(237, 237, 237, 0) 0%,
    rgba(169, 169, 169, 0.2) 100%
  );
  opacity: 0.6;
  border: none;
}
.bcx {
  font-size: 27.44px;
  display: inline;
}

#stakeBtn {
  border-radius: 46.5px;
  border: 2px solid;
  background: #2275ff;
  color: #ffffff;
  width: 35%;
  bottom: 10%;
  position: absolute;
}
*:focus {
  outline: none;
}

/* --------------------------------------- --------------------------------------------------------------------------------*/

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .radio-block {
    position: relative;
    height: 50px;
    border-radius: 40px;
    background-color: #1410171a;

    margin-top: 0px;
  }

  .selected {
    position: absolute;
    top: 5px;
    left: 10px;
    width: calc(50% - 10px);
    height: calc(100% - 10px);
    border-radius: 40px;
    transition: transform 0.2s;
    background-color: #1e1e1e;
  }

  label {
    position: relative;
    z-index: 100;
    width: 50%;
    padding: 12px !important;
    transition: color 0.2s;
    text-align: center;
    cursor: pointer;
  }

  input[type="radio"]:checked + label {
    color: #fff;
  }

  input[type="radio"]:checked + .off-label + .selected {
    transform: translateX(100%);
  }

  .sr-only,
  input[type="radio"] {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    border: 0 !important;
    clip-path: inset(50%) !important;
    white-space: nowrap !important;
  }

  /* ------ Input field ------ */

  form .form-row .input-data {
    height: 50px;
    display: flex;
  }

  .input-data input,
  .textarea textarea {
    height: 100%;
    justify-content: center;
    border: none;
    font-size: 17px;
    border-bottom: 3px solid #777778;
    display: inline;
    width: 80%;
  }
  .inputField {
    background: linear-gradient(
      180deg,
      rgba(237, 237, 237, 0) 0%,
      rgba(169, 169, 169, 0.2) 100%
    );
    opacity: 0.6;
    border: none;
  }
  .bcx {
    font-size: 25px;
    display: inline;
    bottom: -10%;
  }

  #stakeBtn {
    border-radius: 46.5px;
    border: 2px solid #2275ff;
    background: #2275ff;
    color: #ffffff;

    width: 35%;
    margin: 0%;
  }
  *:focus {
    outline: none;
  }
}
